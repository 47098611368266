/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl, KTIcon} from '../../_metronic/helpers'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {
  CardsWidget20,
} from '../../_metronic/partials/widgets'
import { getDashboardReport } from '../modules/reports/pl/core/_requests'
import { useQuery } from 'react-query'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-3'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          stat='135'
          description='Total Customers Checked In'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-3'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          stat='350'
          description='Total Match Points'
          color='#F1916C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
      </div>
      {/* end::Col */}

      
      {/* begin::Col */}
      <div className='col-xxl-3'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          stat='115'
          description='Total Tickets Out Points'
          color='#F1410C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
      </div>
      {/* end::Col */}

      
      {/* begin::Col */}
      <div className='col-xxl-3'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          stat='1500'
          description='Cash In Hand'
          color='#666666'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
      </div>
      {/* end::Col */}

    </div>
    {/* end::Row */}
    
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const getRecord = async () => {
    const data = await getDashboardReport()
    return data
  }
  let { status: reportStatus, isLoading: reportLoading, data: reportResponseData, refetch: refetchReportData } = useQuery([`dashboard-report`], () => getRecord())
 
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-3'>
          <CardsWidget20
            className='h-md-100 mb-5 mb-xl-10'
            stat={reportResponseData ? reportResponseData.total_checked_in : '0'}
            description='Total Customers Checked In'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-3'>
          <CardsWidget20
            className='h-md-100 mb-5 mb-xl-10'
            stat={reportResponseData ? String(reportResponseData.total_match_points) : '0'}
            description='Total Match Points'
            color='#F1916C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        {/* end::Col */}

        
        {/* begin::Col */}
        <div className='col-xxl-3'>
          <CardsWidget20
            className='h-md-100 mb-5 mb-xl-10'
            stat={reportResponseData ? reportResponseData.total_ticket_outs : 0}
            description={`Total Tickets Out (${reportResponseData ? reportResponseData.total_ticket_outs_count : 0})`}
            // description='Total Tickets Out'
            color='#F1410C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        {/* end::Col */}

        
        {/* begin::Col */}
        <div className='col-xxl-3'>
          <CardsWidget20
            className='h-md-100 mb-5 mb-xl-10'
            stat={reportResponseData ? String(Number(reportResponseData.total_money_in) - Number(reportResponseData.total_money_out)) : '0'}
            description='Cash In Hand'
            color='#666666'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>
        {/* end::Col */}

      </div>
      {/* <DashboardPage /> */}
    </>
  )
}

export {DashboardWrapper}
