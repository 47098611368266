import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/token/verify`
export const LOGIN_URL = `${API_URL}/login`
export const LOGOUT_URL = `${API_URL}/logout?from_all=false`
export const LOGOUTALL_URL = `${API_URL}/logout?from_all=true`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GET_TOKEN_BY_REFRESHTOKEN_URL = `${API_URL}/token/refresh`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username:email,
    password,
  })
}

// Server should return AuthModel
export function postLogout(token: string) {
  return axios.post<UserModel>(LOGOUT_URL, {},{
    headers: {
      'Authorization': `Bearer ${token}` 
    }})
}

// Server should return AuthModel
export function postLogoutAll(token: string) {
  return axios.post<UserModel>(LOGOUTALL_URL, {},{
    headers: {
      'Authorization': `Bearer ${token}` 
    }})
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function getTokenByRefreshToken(token: string) {
  return axios.post<UserModel>(GET_TOKEN_BY_REFRESHTOKEN_URL, {
    api_token: token,
  })
}
